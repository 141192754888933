<template>
  <div class="side-menu next">
    <div class="row info-wrapper mob-hide">
      <div class="col-12 mb-4">
        <p class="eoi">EOI Booking</p>
        <h2 class="page-heading">Live Marvellous at Balewadi</h2>
        <p class="align-data">Pune, Maharashtra</p>
      </div>
    </div>
    <div class="unit-details">
      <div class="variant-detail">
        <span class="unit-title">Selected Variant </span>
        <span class="unit-value">{{selectedFloor.value}}</span>
      </div>
      <div class="variant-detail">
        <span class="unit-title">Carpet Area</span>
        <span class="unit-value">{{selectedFloor.area}} Sq.ft</span>
      </div>
      <div class="variant-detail">
        <span class="unit-title">Starting from</span>
        <span class="unit-value">{{selectedFloor.price}} Onwards</span>
      </div>
    </div>
    <!-- <div class="platinum-plan">
      <div class="platinum-plan-title">Platinum Plan</div>
      <div class="plan-body">
        <ul class="platinum-list">
          <li ><span class="platinum-feat">₹ 1,00,000 Token Amount <span class="platinum-feat-op">(Refundable)</span></span></li>
           <li ><span class="platinum-feat">confirmed inventory allocation</span></li>
        </ul>
        <span class="terms-conditions">By clicking, you agree to our <a href="#">Terms and conditions</a></span>
        <button @click="selectPlatinum" class="btn btn-block platinum-btn">Book with Platinum</button>
      </div>
    </div> -->
    <div class="platinum-plan gold-plan">
      <div class="plan-body">
        <div class="token-amount mb-0 mb-lg-5">
          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
          <path d="M0.131996 3.31867C-0.0439987 3.14267 -0.0439988 2.85733 0.131996 2.68133L2.68133 0.131996C2.85733 -0.0439987 3.14267 -0.0439988 3.31867 0.131996L5.868 2.68133C6.044 2.85733 6.044 3.14267 5.868 3.31867L3.31867 5.868C3.14267 6.044 2.85733 6.044 2.68133 5.868L0.131996 3.31867Z" fill="#2B2D42"/>
          </svg>
          ₹ 21,000 Token Amount <span>(Refundable)</span>
        </div>
        <div class="submit-button-section">
        <span class="terms-conditions">By clicking, you agree to our <a href="#" v-b-modal.termsModal>Terms and conditions</a></span>
        <button @click="book" class="btn btn-block platinum-btn">Generate EOI Now</button>
      </div>
      </div>
    </div>
  <div class="disclaimer"><span>Disclaimer:</span> This submission expresses your interest in our property but does not constitute a confirmed booking. The final plot allocation will occur only after completing the formal property booking process.</div>

  <div class="row back-section">
    <button @click="$router.push({name:'Floorplan',query:{...$route.query}})" type="button" class="prevButton">
      <img src="@/assets/images/back-arrow.svg" alt="">
      Back</button>
    </div>
    <div class="col-12 powered-by">Powered by <span class="powered-by-ppistol"> PropertyPistol</span></div>
<TermsAndConditions/>
</div>
</template>
<script>
import { masterJson } from '@/assets/scripts/utility.js'
export default {
  components:{
    TermsAndConditions:()=>import('@/components/TermsAndConditions.vue')
  },
  methods: {
    selectFloor(floor) {
      this.$store.dispatch('setFloor', floor);
    },
    selectGold(){
      this.$store.dispatch('setAmount',21000);
      this.$store.dispatch('setPlanId','Gold');
    },
    selectPlatinum(){
      this.$store.dispatch('setAmount',100000);
      this.$store.dispatch('setPlanId','Platinum');
    },
    book(){
      this.$store.dispatch('setLoading',true);
      this.$router.push({name:'PaymentDetails',query:{...this.$route.query}});
    }
  },
  mounted(){
    this.selectGold()
  },
  computed: {
    variants() {
      return masterJson.Variants;
    },
    selectedFloor() {
      return this.$store.getters.selectedFloor;
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    planId(){
      return this.$store.getters.planId
    }
  }
}
</script>
<style scoped>
h3 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding: 16px;
}

.token-amount{
  color: #000;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
}

.token-amount span{
  font-weight: 400;
  opacity: 0.5;
}

.info-wrapper p.eoi{
    font-weight: 600;
    margin-bottom: 12px;
  }

  .disclaimer{
  color: rgba(0, 0, 0, 0.50);
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 20px;
}

.disclaimer span{
  color: #000;
  font-weight: 500;
}

.side-menu{
   border: none;
   padding-bottom: 0 !important;
}

 .info-wrapper{
    margin-bottom: 20px;
    background: #fff;
    padding-right: 15px;
    border-radius: 10px;
    display: flex;
 }

  .info-wrapper h2{
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
     .info-wrapper .page-heading {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 4px;
  }

  .info-wrapper p {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    text-transform: uppercase;
    opacity: 0.5;
    padding-left: 0px;
    padding-bottom: 0px;
    margin-bottom: 14px;
  }
.info-wrapper .align-data {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    text-transform: none;
    opacity: 0.5;
    padding-left: 0px;
    padding-top: 0;
    margin-bottom: 0;
 }

.back-section{
  padding-left: 15px;
}
.prevButton {
  color: rgba(0, 0, 0, 0.5);
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  margin-top: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: 0.3s;
  padding: 0;
}

.prevButton:hover{
  gap: 10px;
}

.unit-details{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.unit-details .unit-title{
  display: block;
  color: #00000080;
  margin-bottom: 6px;
}
.platinum-list{
  margin-top: 8px;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.platinum-list .radio-label{
  display: flex;
  text-align: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  flex-direction: column;
}

.platinum-list .radio-label{
  font-size: 16px;
  color: #000000;
  font-weight: 600;
}

.platinum-list .radio-label .radio-sub-text{
  font-size: 12px;
  font-weight: 400;
  color: #00000080;
}
.platinum-list .radio-label img{
  margin-bottom: 3px;
}
.platinum-plan{
  margin-top: 30px;
  border-radius: 10px;
  border: 1px solid rgba(2, 103, 193, 0.20);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
}

.platinum-plan-title{
  padding:16px;
  background: #4948991A;
  border-radius: 10px 10px 0px 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.gold-plan .platinum-plan-title{
  background: #0075C41A;
}

.plan-body{
  padding: 16px;
  padding-top: 30px;
}

.plan-body ul{
  list-style-position: inside;
  padding-left: 0;
}

.plan-body ul li{
  list-style-type:none;
  position: relative;
}

.plan-body ul li::before {
  content: '';
  position: absolute;
  height: 6px;
  width: 6px;
  background: #494899;
  transform: rotate(45deg);
  top: 8px;
  left: 0;
}

.gold-plan .plan-body ul li::before{
  background: #0075C4;
}

.plan-body li .platinum-feat{
  position: relative;
  left: 16px;
}
.platinum-feat{
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.platinum-feat .platinum-feat-op{
  font-weight: 400;
  color: #00000080;
}

.platinum-btn{
  background: #444444;
  border-radius: 4px;
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.platinum-btn:focus, .platinum-btn.focus{
  box-shadow: none;
}
.platinum-btn:hover, .platinum-btn:active{
  color: #ffffff;
}

.gold-plan .platinum-btn{
  background-color: #444444;
}

.gold-plan .platinum-btn:hover{
  /* background: #0692f0;
  border-color:#0692f0; */
}
.unit-details .unit-value{
  display: block;
  color: #000000;
}

.terms-conditions{
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
  color: #000;
}

.terms-conditions a{
  color: #000;
  font-weight: 600;
}
.powered-by{
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #00000080;
  text-align: center;
  padding-top: 20px;
  margin-top: auto;
}
.powered-by .powered-by-ppistol{
  color: #000000;
}

@media (max-width:991px) {
  .plan-body{
    padding-top: 16px;
  }
  .disclaimer{
    margin-left: 16px;
    margin-right: 16px;
  }
  .powered-by{
    display: none;
  }
  .mob-hide{
    display: none;
  }
  .prevButton{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .info-wrapper{
    margin-left: 20px;
    margin-right: 16px;
    padding-right: 0;
  }
  .info-wrapper .col-9{
    padding-left: 0;
  }
  .unit-details{
    padding-left: 20px;
    padding-right: 16px;
  }
  .platinum-plan{
    border-radius: 6px;
    margin: 16px 16px 0;
  }
  .token-amount{
    justify-content: center;
  }
  .platinum-plan-title{
    border-radius: 0;
  }
  .back-section{
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
  }

  .submit-button-section{
    position: fixed;
    bottom: 0;
    padding: 16px;
    background: #ffffff;
    left: 0;
    z-index:1003;
    width: 100%;
    border: 1px solid rgba(73, 72, 153, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  }
}
</style>